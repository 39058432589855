/* You can add global styles to this file, and also import other style files */
/* @use '~@angular/material' as mat; */
@import '@angular/cdk/overlay-prebuilt.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';




* {
    font-family: Arial, Helvetica, sans-serif;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
}

input {
    border-radius: 5px;
}

input.placeholder {
    text-align: center;
}

::-webkit-input-placeholder {
    font-style: italic;
    font-weight: normal;
    font-size: 75%;
    text-align: center;
}

:-moz-placeholder {
    font-style: italic;
    font-weight: normal;
    font-size: 75%;
    text-align: center;
}

::-moz-placeholder {
    font-style: italic;
    font-weight: normal;
    font-size: 75%;
}

:-ms-input-placeholder {
    font-style: italic;
    font-weight: normal;
    font-size: 75%;
    text-align: center;
}

.enterPaymentToolTip .tooltip-inner {
    /* Solved with Google Bard AI 7/28/23 
       .tooltip-inner must be referenced here
       This classes must be here and not at the component level .css file */
    color: white;
    background-color: black;
    font-size: 18px;
    font-style: italic;
    max-width: 500px;
    width: 100%;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.cursor-pointer {
    cursor: pointer;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}